var Analytics = function () {
    var lastSendPageview = '';
    var getService = function () {
        if (typeof gtag !== 'undefined') {
            return gtag;
        } else {
            // Debugging service that can be used in development environment
            return function () {
                // console.log.apply(null, ["Analytics action dispatched:"].concat([].slice.call(arguments)));
            };
        }
    };

    var getFacebook = function () {
        if (typeof fbq !== 'undefined') {
            return fbq;
        } else {
            // Debugging service that can be used in development environment
            return function () {
                console.log.apply(null, ["Facebook action dispatched:"].concat([].slice.call(arguments)));
            };
        }
    };

    var sendPageview = function (path) {
        if(lastSendPageview !== path){
            lastSendPageview = path;
            getService()('config', ANALYTICS_ID, {
                'page_path': path
            });

            if(path.endsWith("comment")){
                $.ajax({
                    type: "POST",
                    url: PathHelper('reached_comment_funnel_requests_path'),
                    data: {current_url: getCurrentUrl()}
                })
            }
        }
    };

    var getCurrentUrl = function () {
        return window.location.pathname;
    };

    return {
        sendEvent : function(eventCategoy, eventAction, eventLabel){
            getService()('event', eventAction, {
                'event_category': eventCategoy,
                'event_label': eventLabel
            });
        },


        sendAddToCart : function(serviceType){
            getFacebook()('track', 'AddToCart', {
                content_category: 'Services > ' + serviceType,
                content_ids: [serviceType],
                content_type: serviceType,
                content_name: serviceType,
            });
        },

        sendRecruitmentConversion : function(){
            sendPageview('/housemaid_recruitment/thank_you');
        },

        sendSchedule : function(serviceType){
            getFacebook()('track', 'Schedule', {content_category: 'Services > ' + serviceType});
        },

        sendAddressSelectionPageview: function () {
            sendPageview(getCurrentUrl() + '/address_selection');
        },

        sendPartialAddressSelectionPageview: function () {
            sendPageview(getCurrentUrl() + '/partial_address_selection');
        },

        sendProductSelectionPageview: function () {
            sendPageview(getCurrentUrl() + '/product_selection');
        },

        sendAuthenticationPageview: function () {
            sendPageview(getCurrentUrl() + '/authentication');
        },

        sendCompanySelectionPageview: function () {
            sendPageview(getCurrentUrl() + '/company_selection');
        },

        sendExtraServicesSelectionPageview: function () {
            sendPageview(getCurrentUrl() + '/extra_services');
        },

        sendCommentPageview: function () {
            sendPageview(getCurrentUrl() + '/comment');
        },

        sendDateslotSelectionPageview: function () {
            sendPageview(getCurrentUrl() + '/dateslot_selection');
        },

        sendClubSalePageview: function () {
            sendPageview(getCurrentUrl() + '/club_sale');
        },

        sendClubSaleThankYou: function () {
            sendPageview('/club/thank_you');
        },

        sendDataLayerSignal: function (eventCategory, eventAction) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    'event': 'gtmUaEvent',
                    'gtmUaEventCategory': eventCategory,
                    'gtmUaEventAction': eventAction,
                });
            }
        },


        sendStartRegistration: function (contentName) {
            getFacebook()('track', 'StartRegistration', {content_name: contentName});
        },
        sendStartLogin: function (contentName) {
            getFacebook()('track', 'StartLogin', {content_name: contentName});
        },

        sendRegistrationComplete: function () {
            getFacebook()('track', 'CompleteRegistration', {status: 'success'});
        },

        sendLoginComplete: function () {
            getFacebook()('track', 'CompleteLogin', {status: 'success'});
        },
        sendSubmitApplication: function (contentName) {
            getFacebook()('track', 'SubmitApplication', {content_name: contentName});
        },

        sendCompleteHousemaidApplication: function () {
            getFacebook()('track', 'CompleteHousemaidApplication', {status: 'success'});
        },

        sendCompleteTeamHousemaidApplication: function () {
            getFacebook()('track', 'CompleteTeamHousemaidApplication', {status: 'success'});
        },

        sendViewContentServices: function () {
            getFacebook()('track', 'ViewContent', {
                content_name: 'Services',
                content_category: 'Services',
                content_ids: ['all_services'],
                content_type: 'product_group',
            });
        },

        sendViewContentService: function (serviceType) {
            getFacebook()('track', 'ViewContent', {
                content_name: serviceType,
                content_category: 'Services > ' + serviceType,
                content_ids: [serviceType],
                content_type: 'product',
            });
        },

        sendInitiateCheckout: function(value, serviceType) {
            getFacebook()('track', 'InitiateCheckout', {
                currency: CURRENCY_CODE,
                value: value,
                content_category: 'Services > ' + serviceType,
                content_ids: [serviceType],
                content_type: serviceType,
            });

        },

        sendPurchase: function (value, currency, serviceType, contents) {
            getFacebook()('track', 'Purchase',
                // begin parameter object data
                {
                    currency: currency,
                    value: value,
                    content_category: 'Services > ' + serviceType,
                    contents: contents,
                    content_type: 'product'
                }
                // end parameter object data
            );
        },

        sendAddAddress: function(serviceType){
            getFacebook()('track', 'AddAddress', {
                content_category: 'Services > ' + serviceType,
            });
        },

        sendAddPartialAddress: function(serviceType){
            getFacebook()('track', 'AddPartialAddress', {
                content_category: 'Services > ' + serviceType,
            });
        },

        sendSelectCompany: function(serviceType){
            getFacebook()('track', 'SelectCompany', {
                content_category: 'Services > ' + serviceType,
            });
        },


    };
}();

export default Analytics;

import { I18n } from "i18n-js";
import trans_en from "locales_en.json";
import trans_bg from "locales_bg.json";
import trans_es from "locales_es.json";
import trans_fr from "locales_fr.json";
import trans_pl from "locales_pl.json";
import './supported_locales.js.erb';

const translations = {
    en: trans_en,
    bg: trans_bg,
    es: trans_es,
    fr: trans_fr,
    pl: trans_pl
};


export const i18n = new I18n();
WEBPACK_SUPPORTED_LOCALES.forEach((locale) => {
    i18n.store(translations[locale]);
});

i18n.defaultLocale =  WEBPACK_NATIVE_LOCALE;
import $ from 'jquery';

var OrderPriceStore = function() {
    var eventEmitter = $({});
    var price = {};

    return {
        setPrice: function(newPrice) {
            price = newPrice;
            eventEmitter.trigger('priceChanged');
        },

        getPrice: function() {
            return price;
        },

        onChange: function(callback) {
            eventEmitter.on('priceChanged', function() {
                callback(price);
            });
        }
    };
}();

export default OrderPriceStore;

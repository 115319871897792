import moment from 'moment';
import _ from 'underscore';
import Analytics from '@services/analytics';
import OrderPriceStore from '@services/order_price_store';
import SelectedCityStore from '@components/shared/selected_city_store';
import jQuery from 'jquery';
import * as amplitude from '@amplitude/analytics-browser';

import { i18n } from "config/i18n";

if(typeof AMPLITUDE_KEY !== "undefined" && typeof AMPLITUDE_CURRENT_CLIENT_ID !== "undefined"){
    amplitude.init(AMPLITUDE_KEY, AMPLITUDE_CURRENT_CLIENT_ID);
}

window.moment = moment;
window._ = _;
window.Analytics = Analytics;
window.OrderPriceStore = OrderPriceStore;
window.SelectedCityStore = SelectedCityStore;
window.jQuery = jQuery;
window.$ = jQuery;
window.amplitude = amplitude;
window.i18n = i18n;

